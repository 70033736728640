<template>
  <div>
    <a-drawer :title="`${form.userid ? '编辑' : '新建'}用户`" :width="720" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="姓名" name="name">
              <a-input autocomplete="off" v-model:value="form.name" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="手机号" name="name">
              <a-input size="large" prefix="+86" :maxlength="11" type="tel" placeholder="请输入手机号"
                v-model:value="form.phone" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="部门" name="dept_id">
              <a-select v-model:value="form.dept_id" :options="deptList" placeholder="请选择"></a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="角色" name="role_id">
              <a-select mode="multiple" v-model:value="form.role_id" :options="roleList" placeholder="请选择"></a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-item label="是否业务员" name="status">
              <a-select v-model:value="form.is_salesman" :options="salesmanList" placeholder="请选择"></a-select>
            </a-form-item>
          </a-col> -->
          <a-col :span="12">
            <a-form-item label="状态" name="status">
              <a-select v-model:value="form.status" :options="statusList" placeholder="请选择"></a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template #extra>
        <a-space>
          <a-popconfirm v-if="userinfo?.role_info?.rolename == '系统管理员'" title="确认重置该用户的登录密码为[666666]?" ok-text="确定"
            @confirm="onResetPassword">
            <a-button type="danger">重置密码</a-button>
          </a-popconfirm>

          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
// import UploadFile from '@/components/common/uploadFile.vue';
import service from "@/service/service";
import { message } from "ant-design-vue";
import { mapState } from "vuex";
const rules = {
  name: [
    {
      required: true,
      message: "请输入姓名",
    },
  ],
  phone: [
    {
      required: true,
      message: "请输入手机号",
    },
  ],
  dept_id: [
    {
      required: true,
      message: "请选择部门",
    },
  ],
  role_id: [
    {
      required: true,
      message: "请选择角色",
    },
  ],
  status: [
    {
      required: true,
      message: "请选择状态",
    },
  ],
};
export default {
  components: {
    // UploadFile
  },
  props: ["current"],
  data() {
    return {
      rules,
      sexList: [
        {
          label: "男",
          value: "男",
        },
        {
          label: "女",
          value: "女",
        },
      ],
      salesmanList: [
        {
          label: "否",
          value: 0,
        },
        {
          label: "是",
          value: 1,
        },
      ],
      statusList: [
        {
          label: "正常",
          value: 1,
        },
        {
          label: "已锁定",
          value: -1,
        },
        {
          label: "已注销",
          value: -2,
        },
      ],
      deptList: [],
      roleList: [],
      form: {
        userid: "",
        name: "",
        phone: '',
        dept_id: undefined,
        dept_name: "",
        role_id: [],
        role_name: "",
        is_salesman: "",
        status: "",
      },
      visible: false,
      createLoading: false,
      submitLoading: false,
    };
  },
  created() {
    this.getOptions();
  },
  computed: {
    ...mapState(['userinfo'])
  },
  watch: {
    current: {
      handler(nowCurrent) {
        if (nowCurrent) {
          // console.log(nowCurrent);
          let temp = {};
          for (let key in this.form) {

            if (key == 'role_id') {
              temp[key] = nowCurrent[key] && nowCurrent[key].split(',') || [];
            }
            else temp[key] = nowCurrent[key] || undefined;
          }
          // console.log(temp);
          this.form = temp;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getOptions() {
      const { data: roles } = await service.get_role_lists();
      this.roleList = roles.map((m) => ({ label: m.rolename, value: m.id + "" }));
      const { data: depts } = await service.get_dept_lists();
      this.deptList = depts.map((m) => ({ label: m.dept_name, value: m.id + "" }));
    },
    changeIsDeptAdmin() {
      // console.log(e.target.value);
      if (this.form.is_dept_admin == "是") {
        this.form.is_dept_admin = "否";
      } else {
        this.form.is_dept_admin = "是";
      }
    },
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit("close");
    },
    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = "";
        if (key == 'role_id') {
          temp[key] = [];
        }
      }
      this.form = temp;
    },
    onShow() {
      this.visible = true;
    },
    async onResetPassword() {
      const { code, msg } = await service.reset_pwd({ userid: this.form.userid });
      if (code && code == '1') {
        message.success(msg || '成功');

      }
      else {
        message.error(msg || '失败');
      }
      return true;
    },
    onConfirm() {
      this.$refs['formRef'].validate().then(async () => {
        this.createLoading = true;
        const api = this.form.userid ? "edit_user" : 'add_user';
        let params = { ...this.form };

        if (Array.isArray(params.role_id)) {
          params.role_id = params.role_id.join(',');
        }
        const { code, msg } = await service[api]({
          ...params,
        });
        this.createLoading = false;
        if (code && code == "1") {
          message.success(msg || "成功");
          this.$emit("refreshList");
          this.onClose();
        }
      }).catch((err) => {
        console.log(err)
      })

    },
  }
};
</script>
